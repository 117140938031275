.wysiwyg {

    p, ul, ol {
        margin-bottom: 26px;

        @screen m {
            margin-bottom: 28px;
        }

        @screen l {
            margin-bottom: 30px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    strong, b {
        @apply font-bold;
    }

    em, i {
        @apply italic;
    }

    a:not([class]) {
        @apply link;
    }

    h1, h2, h3, h4, h5, h6 {
        margin-top: 2.8em;
        margin-bottom: 0.75em;

        @screen l {
            margin-top: 2.5em;
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        list-style-type: '–';
        margin-left: 0.4em;
    }

    ol {
        list-style-type: decimal;
        margin-left: 1em;
    }

    li {
        display: list-item;
        position: relative;
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul li {
        padding-left: 1em;
    }

    ol li {
        padding-left: 0.4em;
    }

    &--fact-box {
        h3 {
            @apply font-bold mb-0 mt-0;
        }

        p, ul, ol {
            margin-bottom: 10px;

            @screen m {
                margin-bottom: 15px;
            }

            @screen l {
                margin-bottom: 15px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

}
