@layer components {

    /**
        Værsågod custom Tailwind components
        https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
     */
    .h1 {
        @apply text-40 leading-48 m:text-50 m:leading-60 l:text-58 l:leading-68;
    }

    .h2 {
        @apply text-36 leading-48 m:text-40 m:leading-50 l:text-48 l:leading-62;
    }

    .h3 {
        @apply text-28 leading-38 m:text-34 m:leading-44;
    }

    .h4 {
        @apply text-22 leading-32 m:text-26 m:leading-38 l:text-28 l:leading-42;
    }

    .quote {
        @apply text-28 leading-38 m:text-34 m:leading-44;
    }

    .quote-small {
        @apply text-24 leading-34 m:text-28 m:leading-38;
    }

    .ingress {
        @apply text-22 leading-32 m:text-26 m:leading-38 l:text-28 l:leading-42;
    }

    .body {
        @apply text-16 leading-26 m:text-18 m:leading-28 l:text-20 l:leading-30;
    }

    .caption {
        @apply text-12 leading-18 m:text-16 m:leading-24;
    }

    .copyright {
        @apply text-10 leading-24 m:text-12 m:leading-24;
    }

    .article-subheading {
        @apply text-orange;

        .is-mollersamlingen & {
            @apply text-light-green-100;
        }
    }

    .qa-subheading {
        @apply text-orange !mb-0;

        .is-mollersamlingen & {
            @apply text-light-green-100;
        }
    }

    .collection-title {
        @apply text-20 leading-26 m:text-22 m:leading-30;
    }

    .page-subheading {
        @apply text-28 leading-38 m:text-34 m:leading-44 l:text-48 l:leading-62;
    }

    .large-text {
        @apply text-22 leading-32 m:text-26 m:leading-38 l:text-28 l:leading-42;

    }
    
    .cover-heading {
        @apply text-28 leading-38 m:text-40 m:leading-50 l:text-48 l:leading-62;
    }

    .plug-heading {
        @apply text-36 leading-48 m:text-34 m:leading-44 l:text-58 l:leading-68;
    }
    
    .pad-top {
        @apply pt-110 m:pt-140 l:pt-180;
    }

    .link {
        @apply border-b-1 border-b-current transition-colors duration-300 hover:border-b-thm-primary group-hover:border-b-thm-primary hover:text-thm-primary group-hover:text-thm-primary;
        
        .bg-medvind-green & {
            @apply hover:border-b-medvind-blue group-hover:border-b-medvind-blue hover:text-medvind-blue group-hover:text-medvind-blue;
        }
    }

    .btn {
        @apply inline-flex border-1 border-current rounded-100px max-w-full justify-center items-center transition-colors duration-300 px-20 select-none hover:text-orange;
        height: 46px;

        @screen s {
            min-width: 180px;
        }
        
        .is-medvind & {
            @apply hover:text-medvind-green;
        }

        &[disabled],
        &[aria-disabled="true"] {
            @apply opacity-50 hover:text-current;
        }

        &--auto {
            min-width: 0 !important;
        }
        
        &--selected {
            @apply border-dark-green bg-dark-green text-white;
        }
    }

    .icon {
        @apply relative;

        svg {
            @apply absolute full;
        }

        path {
            fill: currentColor;
        }
    }

    .variable-intrinsic {
        padding-bottom: var(--intrinsic-ratio-base, 100%);

        @screen m {
            padding-bottom: var(--intrinsic-ratio-m, var(--intrinsic-ratio-base, 100%));
        }
        @screen l {
            padding-bottom: var(--intrinsic-ratio-l, var(--intrinsic-ratio-m, var(--intrinsic-ratio-base, 100%)));
        }
    }

    .accordion-hook {
        transform-origin: center;
        transform: rotate(-45deg);
        top: -5px;
        left: 4px;

        button[aria-expanded="true"] & {
            transform: rotate(-225deg);
            top: 0;
        }
    }

    .icon-hover-up-right {
        @apply transition-transform duration-400 ease-out-quint;

        a:hover & {
            transform: translate(2px, -2px);
        }
    }

    .icon-hover-down {
        @apply transition-transform duration-400 ease-out-quint;

        a:hover & {
            transform: translate(0, 2px);
        }
    }

    .headroom {
        will-change: transform;
        transition-property: transform, background-color;
        transition-duration: 0.4s, 0.2s;
        transition-delay: 0s, 0.4s;
    }

    .headroom--pinned {
        transform: translateY(0%);
    }

    .headroom--unpinned {
        transform: translateY(-110%);
    }

    .headroom--scrolled {
        transition: none !important;
    }

    .headroom--not-top {
        background-color: #fff;
    }

    .headroom--top {
        transition-delay: 0s, 0s;
    }

    .report-list-item {
        &:before {
            content: ' ';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: currentColor;

            @screen m {
                display: none;
            }
        }

        &:nth-child(2n+1):before {
            @screen m {
                display: block;
                width: 200%;
            }
            @screen l {
                display: none;
            }
        }

        &:nth-child(3n):before {
            @screen l {
                display: none !important;
            }
        }

        &:nth-child(3n+1):before {
            @screen l {
                display: block;
                width: 300% !important;
            }
        }

        &:after {
            @screen m {
                content: ' ';
                display: block;
                position: absolute;
                top: 40px;
                right: 0;
                width: 1px;
                height: calc(100% - 80px);
                background-color: currentColor;
            }
        }

        &:nth-child(2n):after {
            @screen m {
                display: none;
            }
            @screen l {
                display: block;
            }
        }

        &:nth-child(3n):after {
            @screen l {
                display: none !important;
            }
        }
    }

    .view-mode-radio {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    
    .view-mode-radio + svg {
        opacity: 0.4;
    }
    .view-mode-radio:checked + svg {
        opacity: 1;
    }

    .loader,
    .loader:after {
        border-radius: 50%;
        width: 30px;
        height: 30px;
    }

    .loader {
        position: relative;
        border-top: 2px solid rgba(32, 52, 51, 1);
        border-right: 2px solid rgba(32, 52, 51, 1);
        border-bottom: 2px solid rgba(32, 52, 51, 1);
        border-left: 2px solid transparent;
        transform: translateZ(0);
        animation: load8 0.8s infinite linear;
    }

    @-webkit-keyframes load8 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes load8 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    
    .hero-image-gradient {
        
        .top-is-light & {
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 20%, rgba(0, 0, 0, 0));
        }
        .top-is-dark & {
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 20%, rgba(255, 255, 255, 0));
        }
    }

}
