@layer utilities {

    /**

        Værsågod custom Tailwind utilities
        https://tailwindcss.com/docs/adding-custom-styles#adding-custom-utilities

        These utilities are provided via tailwind.config.js:

        .gpu
        .anti
        .round
        .full
        .center
        .center-x
        .center-y
        .clear
        .scrollable
        .scrollable-x
        .scrollbar-hidden

        .wrapper (container with outer margins and max content width)
        .outer-margins (container with outer margins, but no max-width)
        .gutters (gap-based gutters, both horizontal and vertical)
        .gutters-x (gap-based horizontal gutters)
        .gutters-y (gap-based vertical gutters)
        .pad-wrapper (wrapper meant to be used with pad-gutters children, i.e. like the old .g-wrap)
        .pad-gutters (padding-based gutters, both horizontal and vertical)
        .pad-gutters-x (padding-based horizontal gutters)
        .pad-gutters-y (padding-based vertical gutters)

     */

    .fullheight {
        height: 100vh;
        @supports (--custom:property) {
            height: calc(var(--vh, 1vh) * 100);
        }
    }

    .placed-wrap {
        position: relative;
        height: 0;
    }

    .placed-image {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    .no-scrollbar {
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .bleed {
        padding-left: 0;
        padding-right: 0;
    }

    .ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        max-width: 100%;
        white-space: nowrap;
        vertical-align: bottom;
    }

    .hit {
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            min-width: 44px;
            min-height: 44px;
            position: absolute;
            left: 50%; top: 50%;
            transform: translate(-50%, -50%);
        }
    }

}
