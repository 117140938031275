:root {
    --color-thm-primary: theme('colors.orange.100');
}

.is-mollersamlingen {
    --color-thm-primary: theme('colors.light-green.100');
}
.is-medvind {
    --color-thm-primary: theme('colors.medvind.green');
}

body {
    min-height: calc(100% + 1px);
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    @apply transition-opacity duration-1000 ease-out-sine;
}

.no-js {
    img.lazyload {
        display: none;
    }
}

.no-outline {
    button, a, input, label, textarea, select, option {
        outline: none !important;
    }
}

// Prevent grid blowout on tiny screens
*:not([data-gridoverlay]) > .grid {
    // Max-width = (outerMargins * 2) + (gutter width * number of columns)
    @media (max-width: calc((theme('gutterMap.default') * theme('gridColumns')) + (theme('outerMarginsMap.default') * 2))) {
        grid-template-columns: minmax(0, 1fr);
    }
}
