.page-header {
    &__menu:target {
        display: block !important;
    }
    
    .page-header__logo {
        transition-delay: 0s;
        
    }
    .headroom--unpinned .page-header__logo {
        transition-delay: 0.2s;
    }
    
}

.has-top-image .headroom--top {
    color: #fff !important;
    
    .page-header__logo {
        color: #fff !important;
    }
}
.has-top-image.top-is-dark .headroom--top {
    color: #203433 !important;
    
    .page-header__logo {
        color: #203433 !important;
    }
}
